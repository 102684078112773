.single_project_container {
  display: flex;
  gap: 20px;
}
.single_project {
  width: 70%;
  padding: 10px;
  background-color: white;
}
.SP_header_21 {
  box-shadow: 2px 2px 10px rgba(217, 217, 217, 0.5);
  padding: 10px;
  margin: 10px 0;
}
.SP_header_icon {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: #adadad;
  display: flex;
  align-items: center;
  justify-content: center;
}
.SP_header_icon svg {
  font-size: 24px;
  color: #ffffff;
}
.SP_header_sub {
  margin-right: 10px;
}
.SP_header_sub span {
  color: #00afef;
  margin-right: 5px;
  font-weight: 500;
}
.SP_header3 {
  margin-top: 15px;
}
.SP_header3 h5 {
  font-size: 18px;
  margin-bottom: 5px;
}
.SP_header3 p {
  line-height: 1.7;
  color: #222222;
  font-size: 14px;
  margin-bottom: 10px;
}
.SP_header3_btn span svg {
  font-size: 25px;
}
.SP_phases {
  display: grid;
  /* grid-template-columns: repeat(2, 1fr); */
  gap: 20px;
  align-items: start;
}
.SP_phases_tasks {
  display: grid;
  gap: 10px;
}
.SP_phase {
  border-radius: 10px;
  border: 1px solid #eeeeee;
  box-shadow: 2px 2px 10px rgba(217, 217, 217, 0.5);
}
.SP_phase_header {
  padding: 10px;
  border-bottom: 1px solid #dedede;
}
.SP_phase_header h5 {
  font-size: 15px;
}
.SP_phase_header p {
  font-size: 12px;
}
.SP_phase_header p span {
  color: #00afef;
}
.SP_phase_content {
  padding: 10px;
}
.SP_phase_content:not(:last-of-type) {
  border-bottom: 1px solid #dedede;
}
.SP_phase_descrp h5 {
  font-size: 14px;
  display: flex;
  gap: 5px;
}
.sp_phase_content_icon {
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background-color: #00afef;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.SP_phase_descrp p {
  font-size: 13px;
}
.SP_phase_content:nth-of-type(2) .sp_phase_content_icon {
  background-color: orange;
  color: white;
}
.SP_phase_content:nth-of-type(3) .sp_phase_content_icon {
  background-color: red;
  color: white;
}
.SP_phase_chat {
  background-color: #fafafa;
  padding: 5px 10px;
  border-radius: 10px;
  max-height: 550px;
  overflow-y: auto;
}
/* Project Submission */
.project_submission {
  width: 30%;
  background-color: #ffffff;
  border-radius: 10px;
}
.PS_header {
  padding: 10px;
  border-bottom: 1px solid #dedede;
}
.PS_header h2 {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 10px;
}
.PS_header p {
  font-size: 13px;
  line-height: 1.7;
  color: #4a4a4a;
}
/* PS Form */
.PS_form {
  padding: 10px;
  text-align: center;
}
.PS_form input {
  display: none;
}
.PS_form label {
  display: block;
  width: 100%;
  padding: 20px;
  border: 1px dashed #686868;
  text-align: center;
  border-radius: 5px;
  color: #4a4a4a;
  text-transform: capitalize;
  cursor: pointer;
}
.PS_form label span {
  display: block;
}
.PS_form label svg {
  font-size: 30px;
}
.PS_form button {
  margin: 20px 0;
  padding: 10px 40px;
}
.PS_submitted {
  display: grid;
  gap: 20px;
  padding: 10px;
}
.PS_submitted_contents {
  display: flex;
  gap: 10px;
  align-content: center;
  box-shadow: 2px 2px 10px rgba(229, 229, 229, 0.5);
  border-radius: 5px;
  padding: 25px 20px;
}
.PS_submitted_content_img svg {
  font-size: 40px;
  color: #4a4a4a;
}
.PS_submitted_contents h5 {
  font-size: 14px;
}
.PS_submitted_contents p {
  margin-top: 5px;
  font-size: 12px;
  color: #6a6a6a;
  font-weight: 500;
}


.review-type{
  color:#00afef;
  font-size: 11px;
}


.review-type:before{
  content: "";
  width: 7px;
  height: 2px;
  display: inline-block;
  top: -2px;
  background: #00afef;
  position: relative;
  margin:0 5px;
}