.button {
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  background: none;
  font-size: 1rem;
  font-weight: 600;
}

.button.primary {
  color: #00afef;
  border: 1px solid #00afef;
}
.button.white {
  background-color: #ffffff;
  color: #00afef;
}
.button.danger {
  background-color: #ff0000;
  color: #ffffff;
  border: 1px solid #ff0000;
}
.button.success {
  background-color: #2db563;
  color: #ffffff;
  border: 1px solid #2db563;
}
.button.grey {
  background-color: #adadad;
  color: #ffffff;
  border: 1px solid #adadad;
}
.button.primary-outline {
  color: #00afef;
  border: 1px solid #00afef;
}
.button.shadow {
  box-shadow: 0 0.125rem 0.25rem 0 rgba(58, 59, 69, 0.2);
}
