.dashboard__container {
  padding: 3em;
  min-height: calc(100% - 73px);
  background-color: #f9f9f9;
}
.dashboard__cards {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 10px;
}
.db__card_title {
  font-size: 1.2rem;
  font-weight: 600;
}
.db__card_subtitle {
  font-size: 0.8rem;
  margin: 10px 0;
}
.db__card_subtitle span {
  color: #00afef;
  font-size: 0.8rem;
}
.db__card_descr {
  font-size: 0.9rem;
  line-height: 1.7;
  color: #707070;
  margin: 5px 0;
}
.dashboard_body {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-top: 20px;
  align-items: start;
}
.dashboard_body_left,
.dashboard_body_right {
  display: grid;
  align-items: start;
  gap: 20px;
}
.db__left1 {
  align-items: flex-start;
  gap: 20px;
}
.db__left1-sub {
  width: 100%;
}
.db__left1-sub p {
  display: block;
  font-size: 0.9rem;
  color: #707070;
  font-weight: 500;
}
.db__left1-sub p:not(:last-of-type),
.db__left1-sub div:not(:last-of-type) {
  border-bottom: 1px solid #dedede;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.db__left1-sub p svg {
  margin-right: 10px;
  font-size: 1.4rem;
}
.db__left1-sub p:not(:last-of-type) svg,
.db__left1-sub div:not(:last-of-type) p svg {
  color: #00afef;
}
.db__left1-sub p:last-of-type svg {
  color: #9f9f9f;
}
.db__left1-sub div button {
  padding: 3px 6px;
  font-size: 0.8rem;
  border-radius: 8px;
}
.db__left1-sub div {
  align-items: center;
  margin: 10px 0;
}
.db_notifications:not(:nth-last-of-type(1)) {
  border-bottom: 1px solid #dedede;
  margin-bottom: 10px;
  padding-bottom: 10px;
}
.db__nofi_header {
  margin-bottom: 10px;
}
.db__nofi_header h5 {
  font-size: 14px;
}
.db__nofi_header h6 {
  font-size: 14px;
  color: #707070;
}
.db_notifications p {
  line-height: 1.8;
  margin-bottom: 10px;
  color: #888787;
  font-size: 13px;
}
.db_course_card h2 {
  font-size: 24px;
  margin-bottom: 10px;
}
.db_course_card p span {
  font-size: 13px;
  color: #00afef;
}
.db_courseprogress {
  margin-top: 10px;
}
.db_courseprogress h5 {
  font-size: 14px;
  margin-bottom: 2px;
}
.db_module_card {
  margin-top: 10px;
}
.db_module_card p {
  font-size: 13px;
}
.db_payment_card {
  text-align: center;
  border-top: 1px solid #dedede;
  padding: 10px;
}
.db_payment_card h3 {
  margin: 10px 0;
  font-size: 28px;
}
.db_payment_card h3:nth-of-type(1) {
  color: #06c506;
}
.db_payment_card h3:nth-of-type(2) {
  color: red;
}
.db_payment_card h5 {
  font-size: 15px;
}

.bg-blue-600{
  --tw-bg-opacity: 1;
  background-color: #f9f9f9 !important;
}

.bg-white {
  color: #00afef!important;
}

.bg-blue-500{
  background-color: #00afef !important;
}