.div {
  position: relative;
  display: block;
  text-decoration: none;
  text-transform: uppercase;
  color:#2527338f;
  overflow: hidden;
}

.div p,.div svg {position:relative;z-index:5}

.div span {
  position: relative;
  font-family: Arial;
  letter-spacing: 1px;
  z-index: 1;
}

.div .flux {
    position: absolute;
    top: 66px;
    left: 0px;
    width: 100%;
    height: 300px;
    background: #3db9e7;
    transition: .5s;
}

.div .flux::after,
.div .flux::before {
  content: '';
  width: 120%;
  height: 130%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 75%);
  background: #fff;
}

.div .flux::before {
  border-radius: 45%;
  background: #2a9fc98a;
  animation: animate 5s linear infinite;
}

div .flux::after {
  
  border-radius: 40%;
  background: rgba(20, 20, 20, .5);
  animation: animate 10s linear infinite;
}


@keyframes animate {
  0% {
    transform: translate(-50%, -75%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -75%) rotate(360deg);
  }
}