
.qza-wrap{
  
    height: 45vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 3em;
    font-size:1.2em;
    letter-spacing: 1;
  
}
.qza-alt-text{
  color:#00afef
}


.qza-container{
  font: inherit;
 padding: 0 2em;
}

.qza-review{
	font-weight: 700;
  font-size: 21px;
  line-height: 26px;
}
.qza-center{
  justify-items: center;
  align-items: center;
}

.qza-botton-spacing{
  margin-bottom: 2em;
}
.qza-img-right-spacing{
  margin-right: 1em;
}

.qza-question{
	font-size: 16px;
}

.qza-title{
  font-weight: 700;
  font-size: 1.2em;;
  margin-bottom: 1em;
  line-height: 3;
}

.qza-incorrect{
	background-color: #faebeb;
  border-color: #ffa0a0 !important;
}

.qza-correct{
	background-color: #f0f7f1;
  border-color: #46d064 !important;
}

.qza-question-no,
.qza-incorrect-no,
.qza-correct-no{
  font-size: 16px;
  line-height: 167%;
  font-weight: 700;
  padding: 0 16px;

}
.qza-no-title{
  font-weight: 400;
}

.qza-button-prev,
.qza-button-next{
  width: 44px;
  line-height: 2.5;
  height: 40px;
  padding: 0;
  border: 1px solid transparent;
  border-color: #abafb5;
  color: #abafb5;
  border-radius: 5px;
  font-weight: 600;
  transition: all .22s ease-in;
  cursor: pointer;
  font-family: "Inter",sans-serif;
  text-align: center;
}

.qza-button-spacing{
  margin-right:10px
}
.qza-buttion-inactive{
  border-color: #e6e7e9;
  color:#e6e7e9
}

.qza-question-no{
  padding-left: 0 !important;
  margin-left: 0 !important;
  color:#748190;
}

.qza-correct-no{
  border-left: 1px solid #abafb5;
  border-right: 1px solid #abafb5;
  color: #00b127;
}
.qza-incorrect-no{
  color: #c50202;
}

.qza-top{
	margin: 16px 0 0;
}

.qza-checkbox{
display: none !important;
}

.qza-option{
 padding: 10px;
  margin-bottom: 12px;
  line-height: 20px;
  border: 1px solid #e6e7e9;
  box-sizing: border-box;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
}

.qza-check:before{
  border-radius: 50%;
  border:1px solid #ddd;
}

.qza-check:after{
  border-radius: 50%;
  height: 16px;
  width: 16px;
  display: block;
  content: "";
  position: absolute;
  margin-top: .23em;
  margin-left: .2em;
}

.qza-check:has(input[type="radio"]:checked):after  {
  background-color: #00afef;
}

.qza-check:has(input[type="radio"]:checked):before  {
  border:1px solid #00afef;
}

.qza-option:before{
  content: "";
  width: 22px;
  height: 22px;
  display: block;
  background-repeat: no-repeat;

}

.qza-unselect label{
	 color: #e6e7e9 !important;
}

.qza-unselect:before{
  border: 1px solid #ddd;
  border-radius: 50%;
}


.qza-option.qza-incorrect:before{
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMSAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMC43MzQzNzUiIHk9IjAuNSIgd2lkdGg9IjE5IiBoZWlnaHQ9IjE5IiByeD0iOS41IiBmaWxsPSIjRkY1MDUwIi8+CjxwYXRoIGQ9Ik0xNS4wNzY2IDYuMjQyMTZDMTUuMzc2MSA1Ljk0MjY1IDE1LjM3NjEgNS40NTcwNSAxNS4wNzY2IDUuMTU3NTRDMTQuNzc3MSA0Ljg1ODAzIDE0LjI5MTUgNC44NTgwMyAxMy45OTIgNS4xNTc1NEwxMC4yMzQzIDguOTE1MjNMNi40NzY1NCA1LjE1NzU0QzYuMTc3MDMgNC44NTgwMyA1LjY5MTQ0IDQuODU4MDMgNS4zOTE5MyA1LjE1NzU0QzUuMDkyNDEgNS40NTcwNSA1LjA5MjQxIDUuOTQyNjUgNS4zOTE5MyA2LjI0MjE2TDkuMTQ5NjUgOS45OTk4NUw1LjM5MTkzIDEzLjc1NzVDNS4wOTI0MSAxNC4wNTcgNS4wOTI0MSAxNC41NDI3IDUuMzkxOTMgMTQuODQyMkM1LjY5MTQ0IDE1LjE0MTcgNi4xNzcwMyAxNS4xNDE3IDYuNDc2NTQgMTQuODQyMkwxMC4yMzQzIDExLjA4NDVMMTMuOTkyIDE0Ljg0MjJDMTQuMjkxNSAxNS4xNDE3IDE0Ljc3NzEgMTUuMTQxNyAxNS4wNzY2IDE0Ljg0MjJDMTUuMzc2MSAxNC41NDI3IDE1LjM3NjEgMTQuMDU3IDE1LjA3NjYgMTMuNzU3NUwxMS4zMTg5IDkuOTk5ODVMMTUuMDc2NiA2LjI0MjE2WiIgZmlsbD0id2hpdGUiLz4KPHJlY3QgeD0iMC43MzQzNzUiIHk9IjAuNSIgd2lkdGg9IjE5IiBoZWlnaHQ9IjE5IiByeD0iOS41IiBzdHJva2U9IiNGRjUwNTAiLz4KPC9zdmc+Cg==) !important;
}

.qza-option.qza-correct:before{
 background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMSAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMS4yMzQzOCIgeT0iMC41IiB3aWR0aD0iMTkiIGhlaWdodD0iMTkiIHJ4PSI5LjUiIGZpbGw9IiM0NkQwNjQiLz4KPHBhdGggZD0iTTguOTc1NjggMTMuOTA1N0w1LjU1MDQ1IDEwLjQ1NDJDNS40MDMyNCAxMC4zMDU4IDUuNDAzMjQgMTAuMDY2NSA1LjU1MDQ1IDkuOTE4MThMNS4xOTU1NSA5LjU2NTk5TDUuNTUwNDUgOS45MTgxOEM1LjY5OTEyIDkuNzY4MzggNS45NDEzMiA5Ljc2ODIxIDYuMDkwMTkgOS45MTc4MUw4LjYyMTI3IDEyLjQ2MTJMOC45NzYxOCAxMi44MTc4TDkuMzMwNTkgMTIuNDYwN0wxNS4zNzQyIDYuMzcwNzVDMTUuNTI0MyA2LjIxOTQzIDE1Ljc2OTEgNi4yMTk4NiAxNS45MTg4IDYuMzcxNzFDMTYuMDY2MSA2LjUyMTE3IDE2LjA2NTYgNi43NjEzMyAxNS45MTc4IDYuOTEwMjdMOC45NzU2OCAxMy45MDU3WiIgZmlsbD0id2hpdGUiIHN0cm9rZT0id2hpdGUiLz4KPHJlY3QgeD0iMS4yMzQzOCIgeT0iMC41IiB3aWR0aD0iMTkiIGhlaWdodD0iMTkiIHJ4PSI5LjUiIHN0cm9rZT0iIzQ2RDA2NCIvPgo8L3N2Zz4K) !important;
}

.qza-checkbox+label{margin-left:5px}

.qza-explanation{
	margin-top: 24px;
  background: #f0f7f1;
  padding: 24px 32px;
  border-radius: 10px;
  line-height: 27px;
}

.qza-explanation span{
	color: #00b127fc;
  margin-right: 5px;
}


.qza-button {
  -webkit-appearance: none;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  display: inline-block;
  width: auto;
  margin: 0;
  margin-right: 10px;
  color: inherit;
  font: inherit;
    
  text-decoration: none;
  overflow: visible;
  line-height: 16px;
  padding: 11px 1.25em;
  border: 1px solid transparent;
  border-radius: 5px;
  font-weight: 600;
  transition: all .22s ease-in;
  cursor: pointer;
  font-family: "Inter",sans-serif;
  text-align: center;
}

.qza-button.primary{
  background: #00afef;
  color:#fff;
}

.m-top{
	margin-top:2em;
}

.qza-button.secondary{
	background: #fff;
	color:#000;
}