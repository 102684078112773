/* data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAADGCAYAAAAT+OqFAAAAdklEQVQoz42QQQ7AIAgEF/T/D+kbq/RWAlnQyyazA4aoAB4FsBSA/bFjuF1EOL7VbrIrBuusmrt4ZZORfb6ehbWdnRHEIiITaEUKa5EJqUakRSaEYBJSCY2dEstQY7AuxahwXFrvZmWl2rh4JZ07z9dLtesfNj5q0FU3A5ObbwAAAABJRU5ErkJggg== */


@charset "UTF-8";
/* @font-face {
  font-family: nuevo;
  font-style: normal;
  font-weight: 400;
  src: url(https://stream.nuevodevel.com/vdjs/v11.0.0/fonts/pinko/nuevo.woff)
    format("woff");
} */

@font-face {
  font-family:"MuseoSans900";
  src:url("https://vp.gjirafa.net/vps/vpplayer/assets/fonts/Museo900.otf")
}
.vp-player {
  font-family:"MuseoSans500", "Open Sans", sans-serif !important;
  color:#000;
  margin:0
}

@font-face {
  font-family:"nuevo";
  /* src:url("https://vp.gjirafa.net/vps/vpplayer/assets/icons/fonts-1.1.5/icomoon.eot"); */
  src:
  url("https://vp.gjirafa.net/vps/vpplayer/assets/icons/fonts-1.1.5/icomoon.ttf") format("truetype"),
  /* url("https://vp.gjirafa.net/vps/vpplayer/assets/icons/fonts-1.1.5/icomoon.woff") format("woff") */
  url("https://vp.gjirafa.net/vps/vpplayer/assets/icons/fonts-1.1.5/icomoon.svg") format("svg");
  font-weight:normal;
  font-style:normal;
  font-display:block;
  font-weight: 400;
}


.video-js .vjs-big-play-button {
  background-color: #00b6f0;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  cursor: pointer;
  border: none;
  font-size: 0;
  height: 86px;
  left: 50%;
  position: absolute;
  text-shadow: none;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 86px;
  z-index: 3;
}

.video-js .vjs-big-play-button::before {
  align-items: center;
  content: "\e914";
  font-family: nuevo;
  font-size: 40px;
  margin-left: 6%;
  margin-top: -1%;
  padding: 0;
  position: absolute;
  transform: translate(-50%, -50%);
}

.vjs-marker {
  background-color: #781e0f !important;
}

.video-js .vjs-control-bar {
  width: 100%;
  height: 6em;
  /* position: absolute;
  bottom: 0;
  left: 0;
  right: 0; */
  /* background-color: rgba(43, 51, 63, 0.7); */
  background:#01150254
  /* background-color: transparent; */
}

.video-js .vjs-control-bar > button,
.vjs-volume-panel-horizontal,
.vjs-time-control,
.vjs-playback-rate{
 margin-top: 1.5em !important;
}

.video-js .vjs-progress-control .vjs-progress-holder {
  margin: 0;
  margin-top: 2px;
}

.vjs-progress-control.vjs-control {
  position: absolute;
  width: 100%;
  top: -11px;
  height: 18px;
}


.video-js .vjs-mute-control .vjs-icon-placeholder::before {
  content: "\f107";
}

.video-js .vjs-play-control .vjs-icon-placeholder::before,
.video-js .vjs-play-control.vjs-paused .vjs-icon-placeholder::before {
  /* content: "\e909"; */
  content: "\e913";
}

.video-js .vjs-mute-control.vjs-vol-0 .vjs-icon-placeholder:before {
  /* content: "\e90f"; */
  content: "\e929";
}

.video-js .vjs-mute-control.vjs-vol-1 .vjs-icon-placeholder::before {
  /* content: "\e90e"; */
  content: "\e926";
}

.video-js .vjs-mute-control.vjs-vol-2 .vjs-icon-placeholder::before {
  /* content: "\e90c"; */
  content: "\e926";
}

.video-js .vjs-mute-control.vjs-vol-3 .vjs-icon-placeholder::before {
  /* content: "\e90b"; */
  content: "\e928";
}
.video-js .vjs-play-control.vjs-playing .vjs-icon-placeholder:before {
  /* content: "\e908"; */
  content: "\e914";
}

.vjs-button > .vjs-icon-placeholder::before {
  font-family: nuevo;
  font-size: 14px;
  cursor: pointer;
}

/*  for picture mode e92c" */
/*  for theater mode e92d */
/*  for forward e91a */
/*  for backward e91b */

.video-js .vjs-rewind-control .vjs-icon-placeholder:before {
  /* content: "\e917"; */
  content: "\e99a";
}
.video-js .vjs-forward-control .vjs-icon-placeholder:before {
  /* content: "\e918"; */
  content: "\e92b";
}

.video-js .vjs-rewind-control .num {
  right: 20px;
}
.video-js .vjs-forward-control .num {
  right: 15px;
}

.video-js .vjs-forward-control .num,
.video-js .vjs-rewind-control .num {
  margin-top: -2px;
  font-family: Arial, sans-serif;
  font-size: 8px;
  position: absolute;
}
.video-js .vjs-fullscreen-control .vjs-icon-placeholder::before {
  /* content: "\e910"; */
  content: "\e912";
  font-size: 14px;
}

.video-js.vjs-fullscreen .vjs-fullscreen-control .vjs-icon-placeholder::before {
  /* content: "\e911"; */
  content: "\e924";
}

.vjs-playback-rate .vjs-playback-rate-value {
  pointer-events: none;
  font-size: 1.1em;
  line-height: 3.3;
  text-align: center;
  font-family: Arial, sans-serif;
}

.video-js .vjs-play-progress {
  background-color: #00b6f0;
}
.video-js .vjs-load-progress div {
  background: #00b6f03d;
}

.vjs-has-started .vjs-control-bar,
.vjs-audio-only-mode .vjs-control-bar {
  visibility: visible;
  opacity: 1;
  transition: visibility 0.1s, opacity 0.1s;
}

.vjs-menu-button-popup .vjs-menu {
  bottom: 13px;
  width: 7em;
  left: -10px;
  font-family: Arial, sans-serif;
}

.vjs-menu li.vjs-selected,
.vjs-menu li.vjs-selected:focus,
.vjs-menu li.vjs-selected:hover,
.js-focus-visible .vjs-menu li.vjs-selected:hover {
  background-color: #00b6f0;
  color: #fff;
}

.vjs-fullscreen-control {
  right: 0 !important;
  top: 5px;
  position: absolute !important;
}

.vjs-remaining-time > span:nth-child(2) {
  display: none;
}

.video-js .vjs-duration {
  display: flex;
}

.video-js .vjs-time-divider {
  display: inline-block;
  line-height: 3em;
  width: 1px;
}

.video-js .vjs-time-control {
  /* position: absolute; */
  /* left: 50%; */
  min-width: 2px;
  line-height: 3.3em;
  font-weight: bolder;
  /* padding-left: 3px;
  padding-right: 3px; */
}
.vjs-remaining-time{
  margin-left: -23px;
}

.vjs-duration{
  margin-left: 8px;
}

.vjs-control.vjs-button.vjs-next-control {
  height: 30px;
  position: absolute;
  right: 40px;
  margin-top: 6px;
  width: 6em;
  /* border: 2px solid #00b6f0; */
  color: #fff;
  font-weight: 700;
  transition: all 0.6 ease;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 9px;
}

.vjs-control.vjs-button.vjs-next-control:hover {
  background-color: #00b6f0;
}
/* 
.vjs-control-bar .vjs-control:hover .vjs-control-text {
  opacity: 1;
  top: -25px;
  visibility: visible;
}
.vjs-control-text {
  clip: auto;
  background-color: #fff;
  border: 0;
  color: #000;
  display: block;
  font-family: Arial, sans-serif;
  font-size: 11px;
  left: 50%;
  line-height: 22px;
  opacity: 0;
  padding: 0 5px;
  text-shadow: none;
  top: -10px;
  -webkit-transform: translate(-50%);
  transform: translate(-50%);
  -webkit-transition: opacity 0.3s, top 0.3s;
  transition: opacity 0.3s, top 0.3s;
  visibility: hidden;
  white-space: nowrap;
  width: auto;
  z-index: 2;
}
.vjs-control-text::after {
  background-color: #fff;
  border-radius: 1px;
  content: "";
  display: block;
  height: 14px;
  left: 50%;
  position: absolute;
  top: 100%;
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
  transform: translate(-50%, -50%) rotate(45deg);
  -webkit-transform-origin: 75% 50%;
  transform-origin: 75% 50%;
  width: 14px;
  z-index: -1;
} */

.vjs-tip {
  visibility: hidden;
  bottom: 38px;
}



.LD_banner_video,
.video-js,
.video-js .vjs-tech {
  /* border-radius: 10px;
  padding: 3px; */
  /* background: #0000007d; */
  background-color: transparent;
}

.vjs-control-bar {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
