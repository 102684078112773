.profile {
  background-color: white;
  border-radius: 10px;
}
.profile--banner {
  height: 250px;
  background-image: url("../../assets/img/profile_bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}
.profile--inner-container {
  width: 92%;
  max-width: 1440px;
  margin: 0 auto;
}
.profile--photo {
  position: absolute;
  height: 180px;
  width: 180px;
  border-radius: 50%;
  border: 12px solid #555;
  bottom: -60px;
  left: 50%;
  transform: translateX(calc(-50% - 3%));
  background-color: #dedede;
}
.profile--photo img {
  width: 100%;
}
.profile--photo-btn {
  position: relative;
}
.profile--photo-btn input {
  display: none;
}
.profile--photo-btn label {
  cursor: pointer;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 64px;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 18px;
  font-size: 13px;
  text-align: center;
  top: -55px;
  border-bottom-left-radius: 120px;
  border-bottom-right-radius: 120px;
  color: #fff;
  font-weight: 600;
}
/* Profile Sections */
.profile-sub-section {
  width: 92%;
  text-align: center;
  margin: 80px auto 20px auto;
}
.profile--container {
  width: 92%;
  max-width: 1440px;
  margin: 20px auto;
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 80px;
}
.profile-sections {
  width: 100%;
}
.profile--name {
  padding-bottom: 20px;
  border-bottom: 1px solid #dedede;
  margin-bottom: 20px;
}
.profile--name h2 {
  font-size: 30px;
}
.profile--name h4 {
  color: #686666;
  margin-top: 4px;
  font-size: 16px;
}
.profile-name-text p {
  font-size: 13px;
  color: #777;
  margin-top: 5px;
  line-height: 1.4em;
}
.profile-course-text h4 {
  margin-bottom: 10px;
  font-size: 15px;
}
/* Profile Header */
.profile--header {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}
.profile--header .icon img {
  width: 15px;
}
.text p {
  font-size: 14px;
  color: #555;
  font-weight: 600;
}
.icon {
  display: flex;
  align-items: center;
  padding: 0 20px;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
}
.icon span {
  font-size: 14px;
  color: #160992;
  text-decoration: underline;
  margin-left: 4px;
}
.profile-sects {
  margin-bottom: 30px;
}
.social-link {
  margin-bottom: 30px;
}
.social-link-edit {
  display: flex;
  align-items: center;
  color: #160992;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
}
.social-link-edit img {
  width: 23px;
}
.social-link-edit span {
  text-decoration: underline;
  font-size: 14px;
  margin-left: 2px;
}
.social-media-sub-link {
  display: flex;
  align-items: center;
}
.social-media-sub-link img {
  width: 20px;
  margin-right: 10px;
}
.social-media-sub-link span {
  font-size: 15px;
  color: #555;
}
.profile-about-bio p,
.profile-certificate-cert {
  line-height: 1.6em;
  font-size: 15px;
  color: #444;
}
/* Project */
.project-list {
  margin-bottom: 10px;
  border-bottom: 1px solid #dedede;
  padding-bottom: 10px;
}
.project-list p {
  font-size: 12px;
  color: #444;
  margin-bottom: 5px;
}
.project-list a {
  font-size: 13px;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  color: #160992;
  text-decoration: none;
}
/* Skills */
/* .skill-list {
  padding: 10px 0;
  margin-bottom: 5px;
  border-bottom: 1px solid #dedede;
  font-size: 14px;
} */
.skills-form {
  margin-top: 20px;
}
/* Pills */
.pill-btn,
.skill-list {
  display: inline-block;
  border: 1px solid #888;
  font-size: 13px;
  background: none;
  margin: 10px 10px 10px 0;
  padding: 6px 20px;
  border-radius: 20px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
}
.pill-btn:hover {
  border-color: #444;
}
.pill-close {
  width: 25px;
}

/* Certificate */
.form--certificate {
  margin-top: 10px;
}
.form--certificate input {
  display: none;
}
.form--certificate label {
  padding: 10px 20px;
  border: 1px solid #555;
  color: #555;
  display: inline-block;
  border-radius: 3px;
  transition: background, color 0.3s;
  cursor: pointer;
}
.form--certificate label:hover {
  background: #555;
  color: #fff;
}
/* Language */
.language--form {
  margin-top: 20px;
}
/* Profile Form */
.profile--form {
  padding: 0 !important;
}
