.welcome_bg {
  width: 100%;
  min-height: calc(100dvh - 65px);
  background-image: url(../../assets/img/welcome-coding-image3.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 10px;
  background-color: #b5b5b5;
  box-shadow: 2px 2px 10px rgba(223, 223, 223, 0.5);
  background-blend-mode: screen;
  position: relative;
}
.welcome_content {
  text-align: center;
  text-transform: uppercase;
}
.welcome_content h4 {
  font-size: 16px;
  margin-bottom: 60px;
  font-weight: 600;
  border-bottom: 1px solid #dbdada;
  background-color: #00afef;
  color: #ffffff;
  padding: 20px 0;
  border-radius: 5px;
}
.welcome_content p {
  margin-bottom: 10px;
  font-size: 13px;
}
.welcome_content_text {
  font-weight: 900;
  font-size: 55px;
  max-width: 80%;
  margin: 0 auto 20px auto;
}
.welcome_content_text strong {
  font-size: inherit;
  color: #00afef;
}
.welcome_content_countdown {
  font-size: 50px;
  font-weight: 900;
}
.welcome_content_bottom {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  display: flex;
  align-items: flex-end;
  gap: 20px;
}
.Welcome_bottom_link {
  padding: 15px;
  display: inline-block;
  font-size: 14px;
  background: none;
  border: 1px solid #00afef;
  color: #00afef;
  border-radius: 5px;
  text-decoration: none;
  transition: height 0.3s ease;
  width: 250px;
  height: 50px;
  overflow: hidden;
}
.Welcome_bottom_link svg {
  font-size: 18px;
}
.Welcome_bottom_link:hover {
  height: auto;
  color: #ffffff;
  box-shadow: 2px 2px 10px rgba(196, 196, 196, 0.4);
  background-color: #00afef;
}
.Welcome_bottom_link h5 {
  display: flex;
  font-size: 16px;
  gap: 5px;
  align-items: center;
  font-weight: 600;
}
.Welcome_bottom_link p {
  font-size: 13px;
  padding: 15px 0;
  line-height: 1.7;
}
.Welcome_bottom_link a {
  border-bottom: 1px solid #ffffff;
  font-size: 12px;
  font-weight: 500;
  color: #ffffff;
}
.Welcome_bottom_link a:hover {
  color: #ffffff;
}
