.form {
  border-radius: 7px;
  max-width: 600px;
  /* min-width: 320px; */
  margin: auto;
  background-color: #ffffff;
  padding: 20px;
}

.form-control {
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
}

.form-descp {
  color: #9fa2b4;
}

.form-group {
  margin-bottom: 17px;
}

.form-group label {
  font-size: 0.8rem;
  margin-bottom: 5px;
}

.form-control,
.form-group input:not([type="checkbox"]),
.form-group input:not([type="radio"]),
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 10px 8px;
  font-size: 11px;
  border: 1px solid #f0f1f7;
  background-color: #fcfdfe;
  color: #898383;
  display: block;
  outline: none;
  border-radius: 5px;
  box-sizing: border-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-group textarea {
  height: 120px;
  overflow-y: auto;
}
.form--grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0 10px;
}
.form-group-sub {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}
.form-group-sub input {
  width: 25px;
  height: 25px;
}
.form-group-sub span {
  margin-left: 10px;
  font-size: 13px;
  color: #333;
}
input:focus {
  color: #6e707e;
  background-color: #fff;
  border-color: #bac8f3;
  outline: 0;
  box-shadow: 0 0 3px 0rem rgb(78 115 223 / 11%);
}

.msg.success,
.msg.error {
  text-align: center;
  padding: 2px;
  border-radius: 5px;
}

.msg.error {
  color: #dc3545;
}

.msg.success {
  color: #198754;
}
