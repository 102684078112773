.lessons_grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
  width: 80%;
  margin: auto;
}
.lessons_content {
  border-radius: 10px;
  background-color: #ffffff;
  padding: 1.6em;
  text-decoration: none;
  color: black;
  transition: all 0.3s ease;
  border: 1px solid #f0f0f0;
  cursor: pointer;
}
.lessons_content:hover {
  box-shadow: 2px 2px 10px rgba(209, 209, 209, 0.5);
}
.lessons_content img {
  width: 40px;
  height: 40px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  margin-bottom: 10px;
}
.lessons_content h4,
.lessons_content span {
  padding: 0 10px;
}
.lessons_content h4 {
  font-size: 15px;
  font-weight: 600;
}
.lessons_content span {
  color: grey;
  font-size: 13px;
}

.flex-reverse{
  display: flex;
  flex-direction: row-reverse;
}