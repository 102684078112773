.certificates_container {
  overflow-x: auto;
}
.certificates {
  width: 1123.2px;
  margin: 20px auto;
  color: white;
  height: 796.8px;
  position: relative;
  overflow: hidden;
}
.logoBg {
  filter: opacity(0.12);
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}
.certificate_frame {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  border-width: 8px;
  border-style: solid;
  margin: 70px;
  z-index: 1;
}
.certificate_box {
  width: 50px;
  height: 50px;
  position: absolute;
  z-index: 1;
  border-width: 8px;
  border-style: solid;
}
.certificate_box1 {
  left: -50px;
  top: -50px;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  border-bottom-left-radius: 50%;
}
.certificate_box2 {
  left: -50px;
  bottom: -50px;
  border-top-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
}
.certificate_box3 {
  right: -50px;
  top: -50px;
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  border-bottom-right-radius: 50%;
}
.certificate_box4 {
  bottom: -50px;
  right: -50px;
  border-top-right-radius: 50%;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
}
.certificate_outer_frame {
  border-width: 5px;
  border-style: solid;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: 50px;
  z-index: 1;
}
.certificate_frame_box {
  height: 170px;
  width: 170px;
  position: absolute;
  transform: rotate(45deg);
}
.certificate_frame_box1 {
  top: -86px;
  left: -86px;
  border-right: 5px solid;
}
.certificate_frame_box2 {
  top: -86px;
  right: -86px;
  border-bottom: 5px solid;
}
.certificate_frame_box3 {
  bottom: -86px;
  left: -86px;
  border-top: 5px solid;
}
.certificate_frame_box4 {
  bottom: -86px;
  right: -86px;
  border-left: 5px solid;
}
.certificate_content_container {
  padding: 20px;
  text-align: center;
}
.certificate_logo {
  display: flex;
  justify-content: center;
  align-items: center;
}
.certificate_logo img {
  width: 55px;
}
.certificate_logo h4 {
  text-transform: uppercase;
  font-family: "Arial Rounded";
  font-size: 22px;
}
.certificate_content {
  margin: 40px 0;
}
.certificate_title {
  font-family: "Open Sans Condensed";
  /* font-family: "Comalle"; */
  /* letter-spacing: 1px; */
  font-size: 33px;
  /* font-weight: 200; */
  font-weight: 600;
}
.certificate_content h2 {
  font-family: "Lucida";
  font-size: 45px;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  padding-bottom: 10px;
  width: 80%;
  margin: 50px auto 30px auto;
}
.certificate_course {
  font-size: 27px;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
}
.certificate_descrp {
  font-size: 25px;
  margin: 10px 0;
}
.certificate_cordinators {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: 100px auto 20px auto;
  position: relative;
}
.certificate_head_divider {
  width: 200px;
  height: 0.5px;
  background-color: #cfcfcf;
  margin-bottom: 10px;
}
.certificate_head i {
  font-size: 14px;
  letter-spacing: 2px;
}
.certificate_head p {
  font-size: 18px;
  font-weight: 500;
}
.certificate_footer {
  display: flex;
  width: 80%;
  margin: 50px auto 0 auto;
  justify-content: space-between;
}
.certificate_footer h3 {
  font-size: 16px;
  font-family: "Montserrat", "sans-serif";
  font-weight: 600;
}
