.notifications {
  background-color: #ffffff;
}
.notifications_header {
  padding: 10px;
  margin: 10px 0;
}
.notification_content {
  padding: 10px;
  border-bottom: 1px solid #dedede;
}
.notification_content_sub {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.notification_content_sub h4 {
  font-size: 16px;
  color: #656565;
}
.notification_content_sub h5 {
  color: #8f8f8f;
  font-size: 13px;
}
.notification_content p {
  line-height: 1.7;
  color: #717171;
  font-size: 13px;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  width: 100%;
}

.btn-notice{
  padding: 5px !important;
  font-size: 13px;
  margin-left: 15px;
}