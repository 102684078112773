

.max-w-sm {
    max-width: 24rem;
  }
  .p-10 {
    padding: 2.5rem;
  }
  
  .bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(37,99,235,var(--tw-bg-opacity));
  }
  .min-h-screen {
    min-height: 100vh;
  }
  
  .w-6 {
    width: 1.5rem;
  }
  .h-6 {
    height: 1.5rem;
  }
  .justify-between {
    justify-content: space-between;
  }
  
  .font-bold {
    font-weight: 700;
  }
  .text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
  .text-white {
    --tw-text-opacity: 1;
    color: rgba(255,255,255,var(--tw-text-opacity));
  }
  
  .justify-center {
    justify-content: center;
  }
  .flex {
    display: flex;
  }
  .my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
  
  .text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
  .text-center {
    text-align: center;
  }
  
  .text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  
  .p-8 {
    padding: 2rem;
  }
  .bg-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(59,130,246,var(--tw-bg-opacity));
  }
  
  rounded-lg {
    border-radius: .5rem;
  }
  .flex-col {
    flex-direction: column;
  }
  
  .space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(.25rem * var(--tw-space-y-reverse));
  }
  .underline {
    text-decoration: underline;
  }
  
  .py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .bg-yellow-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(245,158,11,var(--tw-bg-opacity));
  }
  
  .rounded-lg {
    border-radius: .5rem;
  }
  
  .shadow-xl {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
  }
  
  button, input, optgroup, select, textarea {
   
    line-height: inherit;
    color: inherit;
    border:none
  }
  
  .check-wrap {
    color: #34eb95;
    width: 40px;
  }

  .bg-white{
    background: #fff;
  padding: 30px;
  color: #2563eb;
  border-radius: 6px;
  }

  #USD,#NGN,#monthly,#quaterly,#yearly{
    width: 40px !important;
    margin: 4px 0px;
  }
  label{
    cursor: pointer;
  }