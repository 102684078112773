.loading_screen {
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top:25%
  }
  .loading_cover {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    border: 2px solid #8adbf8;
    border-left: none;
    margin: auto;
    display: flex;
    align-items: center;
    animation: spinner_anim 1.5s linear infinite;
  }
  @keyframes spinner_anim {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  