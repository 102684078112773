.ErrorPage {
  min-height: 100vmin;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f8f8;
}
.ErrorPage_content {
  text-align: center;
}
.ErrorPage_content h2 {
  font-size: 70px;
  font-weight: 800;
}
.ErrorPage_content a {
  padding: 10px 30px;
  font-size: 13px;
  display: inline-block;
  margin: 10px 0;
  border-radius: 5px;
  background-color: #00afef;
  color: white;
}
