.project__container {
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  padding: 20px;
}
.project__img img {
  width: 100%;
  max-height: 400px;
}
.project__card {
  box-shadow: 2px 2px 10px rgba(197, 197, 197, 0.2);
  border-radius: 8px;
  margin-bottom: 20px;
  min-width: 300px;
  background-color: #ffffff;
}
.project__card1 {
  padding: 20px;
}
.project__card1 h2 {
  font-size: 1.6rem;
  font-weight: 500;
}
.project__card1 span {
  display: block;
  margin-bottom: 25px;
  font-size: 0.9rem;
  font-weight: 600;
}
.project__card1 h4 {
  font-size: 1.2rem;
  font-weight: 500;
}
.project__card1 p {
  line-height: 1.9;
  color: #676767;
  font-size: 0.9rem;
}
.project__card1 a {
  display: inline-flex;
  align-items: center;
  margin: 10px 0;
  font-weight: 500;
}
.project__card1 a svg {
  font-size: 1.5rem;
}
.project__card2 {
  padding: 20px 0;
  text-align: center;
}
.project__card2-title {
  padding: 0 20px;
  width: 60%;
  margin: 0 auto;
  margin-bottom: 30px;
}
.project__card2-title h2 {
  text-align: center;
  margin-bottom: 20px;
}
.project__timer h3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 2rem;
  text-transform: uppercase;
}
.project__timer h3 span {
  font-size: 1rem;
}
.project__timer.expired{
  color:red;
}
.project__card-body {
  width: 60%;
  margin: 0 auto;
}
