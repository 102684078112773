.nav_bar {
  padding: 10px;
  box-shadow: 2px 2px 10px rgba(217, 217, 217, 0.5);
  min-width: 320px;
  background-color: #ffffff;
}
.navbar__menu {
  font-size: 2rem;
  cursor: pointer;
  display: none;
}
.navbar__left h2 {
  margin-bottom: 5px;
  font-size: 1.4rem;
  font-weight: 500;
  color: #606060;
  text-transform: capitalize;
}
.navbar__left h3 {
  color: #1ab61a;
  font-weight: 500;
  font-size: 0.92rem;
}
.navbar__profile {
  color: #606060;
  align-items: center;
}
.navbar_notification {
  margin-right: 10px;
}
.navbar_notification svg {
  font-size: 1.3rem;
  margin-right: 10px;
}
.navbar_notification {
  border-right: 1px solid #dedede;
  color: #474646;
}
.navbar_profile {
  color: #00afef;
}
.navbar_profile svg {
  font-size: 30px;
  border: 1px solid #dedede;
  border-radius: 50%;
}
.navbar_logout button {
  color: red;
  font-size: 13px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin: 0 10px;
  padding: 3px 10px;
}
