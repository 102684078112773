.awaiting {
    text-align: center;
    padding: 10px;
  }
  .awaiting img {
    width: 40%;
    margin: 20px 0;
    min-width: 250px;
  }
  .awaiting p {
    font-size: 15px;
    line-height: 1.6;
    color: #585858;
  }
  .awaiting h5 {
    font-size: 20px;
    margin: 20px 0;
  }
  