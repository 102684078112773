.card__container,
.card2,
.project_card {
  border-radius: 8px;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 0 0.125rem 0.25rem 0 rgba(181, 181, 181, 0.2);
  min-width: 280px;
}
.card__container h4,
.card2-title p {
  color: #53ccf7;
  font-size: 1rem;
  font-weight: 500;
}
.card__container h2 {
  font-size: 1.3rem;
  font-weight: 500;
  margin-top: 10px;
}
.card__icon {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: #36c7fc;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card__icon svg {
  font-size: 1.5rem;
  color: #ffffff;
}
.card__container:nth-child(even) {
  background-color: #53ccf7;
  color: #ffffff;
}
.card__container:nth-child(even) .card__icon {
  background-color: #ffffff;
}
.card__container:nth-child(even) h4 {
  color: #ffffff;
}
.card__container:nth-child(even) .card__icon svg {
  color: #53ccf7;
}
/* Card 2 */
.card2 {
  box-shadow: none;
}
.card2__icon {
  margin-right: 20px;
}
.card2__icon img {
  width: 100px;
  height: 100px;
  padding: 5px;
  object-fit: contain;
  border-radius: 50%;
  border: 2px solid #dedede;
}
.card2-title {
  margin-bottom: 20px;
}
.card2-title h4 {
  font-size: 1.1rem;
  color: #6c6c6c;
}
.card2-title a {
  font-size: 0.9rem;
  color: #00afef;
}
/* Module Card */
.module_card {
  border-radius: 10px;
  background-color: #ffffff;
  border: 1px solid #f7f7f7;
  position: relative;
  /* min-width: 27%; */
  min-width: 310px;
}
.module_flexed {
  align-items: center;
  margin-bottom: 10px;
}
.module_flexed p {
  margin-left: 5px;
}
.module_card img {
  height: 200px;
  object-fit: cover;
  object-position: center;
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.module_card_content {
  padding: 10px;
}
.module_status {
  position: absolute;
  top: 10px;
  left: 10px;
  border-radius: 5px;
  padding: 5px 20px;
  font-size: 13px;
  background-color: rgba(71, 71, 71, 0.2);
  color: white;
}
.module_card_subtitle {
  margin-bottom: 5px;
}
.module_card_subtitle span {
  color: #00afef;
}
.module_card_content p {
  font-size: 13px;
}
.module__title {
  margin-bottom: 5px;
  font-size: 22px;
  font-weight: 800;
}
/* Project Card */
.project_card {
  padding: 0;
  text-decoration: none;
  color: #000000;
}
.p_card_header1,
.p_card_content {
  padding: 10px;
}
.p_card_header2 {
  padding: 0 10px 10px 10px;
  border-bottom: 1px solid #dedede;
}
.p_card_icon {
  height: 42px;
  width: 42px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.p_card_icon svg {
  font-size: 25px;
}

/* .project_card:nth-of-type(1n + 1) .p_card_icon,
.project_card:nth-of-type(1n + 1) .p_card_header1 span {
  background-color: #00afef;
  color: white;
}
.project_card:nth-of-type(2n + 2) .p_card_icon,
.project_card:nth-of-type(2n + 2) .p_card_header1 span {
  background-color: orange;
  color: white;
}
.project_card:nth-of-type(3n + 3) .p_card_icon,
.project_card:nth-of-type(3n + 3) .p_card_header1 span {
  background-color: green;
  color: white;
}
.project_card:nth-of-type(4n + 3) .p_card_icon,
.project_card:nth-of-type(4n + 3) .p_card_header1 span {
  background-color: orangered;
  color: white;
} */

.project_card .p_card_icon.current, .p_card_header1 span.current {
  background-color: orange;
  color: white;
}

.project_card .p_card_icon.submited, .p_card_header1 span.submited {
  background-color: green;
  color: white;
}

.project_card .p_card_icon.completed, .p_card_header1 span.completed {
  background-color: #00afef;
  color: white;
}

.project_card .p_card_icon.expired, .p_card_header1 span.expired {
  background-color: orangered;
  color: white;
}


.p_card_header1 span {
  padding: 5px 20px 4px 20px;
  font-size: 13px;
  border-radius: 5px;
}
.p_card_header2 h2 {
  font-size: 23px;
}
.p_card_header2 h5 {
  font-size: 15px;
  margin-bottom: 10px;
}
.p_card_header2 p {
  font-size: 13px;
}
.p_card_header2_resp {
  margin-top: 10px;
  background-color: #d6dbdd;
  color: #111111;
  display: inline-block;
  border-radius: 5px;
  font-size: 13px;
  padding: 5px;
}
.p_card_content p {
  margin-bottom: 10px;
}
.p_card_content_descrp {
  font-size: 13px;
  line-height: 1.7;
  color: #6c6c6c;
}
.p_card_content p:not(:first-child) {
  font-weight: 500;
}
/* Payments Cards */
.payments_summary_card {
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}
.payments_summary_card:nth-child(odd) {
  background-color: #ffffff;
}
.payments_summary_card:nth-child(even) {
  background-color: #00afef;
  color: white;
}
.payments_summary_card:nth-child(even) h5 {
  color: white;
}
.payments_summary_card h5 {
  color: #878787;
  font-size: 14px;
  margin-bottom: 10px;
}


.card__container, .card2, .project_card {
  border-radius: 4px;
  padding: 40px;
}

.card-continue {
  font-size: 0.9rem;
  color: #fff;
  font-weight: bolder;
  background: #00afef;
  padding: .5em;
}