.payments_cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin-top: 10px;
}

/* Payments Table */
.payments_table_header {
  text-align: right;
  margin-bottom: 10px;
}
.payments_table_container {
  background-color: #ffffff;
  margin-top: 20px;
  border-radius: 10px;
  padding: 10px;
}
.payments_table table {
  width: 100%;
  text-align: left;
}
.payments_table table tr {
  border-top: 1px solid #dedede29;
}
/* .payments_table thead {
  background-color: #222222;
  color: white;
} */
.payments_table table th,
.payments_table td {
  padding: 11px;
  font-size: 13px;
}
.payments_table_sn span {
  background-color: #00afef;
  color: white;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: inline-block;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
}
/* Payments Modal */
.payments_modal {
  position: fixed;
  top: 0;
  left: 250px;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.payment_modal_close {
  position: absolute;
  top: 20px;
  right: 20px;
  height: 40px;
  width: 40px;
  background-color: #222222;
  color: white;
  border-radius: 50%;
  font-size: 20px;
}

.react-tabs__tab-list {
  border:none;
  margin: 0 0 10px;
  padding: 0;
  display: flex;
}

.react-tabs__tab--selected {
  font-weight: 500;
  padding-bottom: 5px;
  border: 0;
  border-bottom: 2px solid #000;
}

.react-tabs__tab{
  width: 50%;
  cursor: pointer;
}

.tr-bg-success{
  color: #1ee0ac;
}
.tr-bg-warning {
  color: #f4bd0e;
}
.tr-bg-danger {
  color: #e85347;
}