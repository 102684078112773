.dp__body {
  text-align: center;
  padding: 20px 0;
  margin: 20px 0;
}
.dp__body-icon {
  font-size: 12rem;
  color: #727272a8;
}
.dp__body img {
  height: 150px;
  width: 150px;
  object-fit: contain;
  display: block;
  border-radius: 100%;
  border: 5px solid #00afef;
  background-color: #000000;
}
.dp__body input {
  display: none;
}
.lp__signature h4 {
  font-size: 0.9rem;
  text-align: center;
  margin-top: 40px;
  font-weight: 600;
}
