.lp__container {
  min-height: 100vh;
  background-color: #eeeeee;
  display: flex;
}
.lp__content {
  width: 25%;
  max-width: 600px;
  margin: auto;
  background-color: #ffffff;
  padding: 3rem 30px;
  border-radius: 8px;
}
.lp__header {
  text-align: center;
  padding-bottom: 10px;
  /* border-bottom: 2px solid #9c9c9c5c; */
}
.lp__header h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  font-weight: 500;
}
.lp__header p {
  font-size: 0.9rem;
  color: #707070;
}
.lc__header h3 {
  margin: 20px 0;
  font-weight: 700;
  font-size: 1.6rem;
  text-transform: capitalize;
  color: #252733;
}
.lp__body {
  margin: 20px 0;
}
.lp__details h3 {
  margin-bottom: 25px;
  text-transform: uppercase;
  font-size: 1.09rem;
}
.lp__details h3 span {
  text-transform: capitalize;
  color: #767676;
  font-size: 0.9rem;
}
.lp__upload {
  display: block;
  border: 2px dotted #252733;
  color: #252733;
  padding: 12px;
  border: 2px dashed #2527338f;
  color: #2527338f;
  text-align: center;
  margin: 30px 0;
  border-radius: 5px;
  cursor: pointer;
}
.lp__upload-icon {
  font-size: 1.8rem;
}
.lp__details-upload input {
  display: none;
}
.lp__btn button {
  width: 100%;
}
