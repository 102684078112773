.modal-content {
  border-radius: 1px;
  border: 1px solid #ddd;
}
/* Profile Modal */
/* Modal */
.darkBG {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
}
.modal--container {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  top: 30px;
  width: 500px;
  z-index: 20;
}
.profile--modal {
  position: relative;
  background-color: #fff;
}
.modal--head {
  padding: 20px 10px;
  border-bottom: 1px solid #dedede;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  background-color: #fff;
  border-radius: 3px 3px 0 0;
}
.modal--body {
  padding: 20px 10px 0 10px;
  margin: 60px 0 0 0;
  max-height: 400px;
  overflow-y: auto;
}
.modal--heading {
  margin: 0;
  color: #2c3e50;
  font-weight: 500;
}
.modal--sub-heading {
  margin-bottom: 20px;
}
.modal--sub-heading p {
  font-size: 13px;
  color: #222;
}
.modal--actions-btns {
  padding: 10px;
  bottom: 3px;
  width: 100%;
  background-color: #fff;
  border-top: 1px solid #dedede;
  border-radius: 0 0 3px 3px;
  text-align: right;
}
.modal--close-btn {
  cursor: pointer;
  font-weight: 500;
  padding: 4px 8px;
  border-radius: 7px;
  border: none;
  font-size: 18px;
  color: #2c3e50;
  background: white;
  transition: transform 0.25s ease;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.06);
  position: absolute;
  right: 0;
  top: 0;
  align-self: flex-end;
  margin-top: -7px;
  margin-right: -7px;
}
.modal--close-btn:hover {
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  transform: scale(1.1);
}
.modal--submit-btn {
  font-weight: 500;
  padding: 11px 28px;
  border-radius: 3px;
  font-size: 0.8rem;
  transition: all 0.25s ease;
}
.modal--submit-btn:hover {
  box-shadow: 0 10px 20px -10px #09aeea;
  transform: translateY(-5px);
  background: #0296cc;
}
.modal-open {
  overflow: hidden;
}
