.space {
  margin: 30px 0;
}

.progress {
  --bs-progress-height: inherit;
}

.progress[role="bar"] {
  height: 6px;
  width: 200px;
  display: grid;
  background-color: white;
  border-radius: 4px;
  border: 1px solid var(--primary);
  padding: 0 2px;
}

.progress[role="bar"]::before {
  animation: barprogress 2s 0.5s forwards;
  content: "";
  height: 3px;
  width: 0;
  background-color: var(--primary);
  position: relative;
  top: 1px;
}

.progress[role="circular"] {
  --percentage: var(--value);
  --size: 58px;

  width: var(--size);
  aspect-ratio: 1;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  display: grid;
  place-items: center;
}

.progress[role="circular"]::before {
  content: "";
  position: absolute;
  animation: progress 2s 0.5s forwards;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: conic-gradient(#00afef calc(var(--percentage) * 1%), #0000ff03 0);
  mask: radial-gradient(white 55%, transparent 0);
  mask-mode: alpha;
  -webkit-mask: radial-gradient(#0000 55%, #000 0);
  -webkit-mask-mode: alpha;
}

.progress[role="circular"]::after {
  counter-reset: percentage var(--value);
  content: counter(percentage) "%";
  font-family: Helvetica, Arial, sans-serif;
  font-size: calc(var(--size) / 5);
  color: #fff;
  position: absolute;
  font-weight: 700;
}

.progress[role="circular"] div {
  background-color: #fff;
  height: 55px;
  width: 55px;
  position: relative;
  border-radius: 50%;
  top: 0px;
}

.progress[role="circular"] div:before {
  height: 49px;
  width: 49px;
  content: "";
  display: block;
  border-radius: 50%;
  position: relative;
  top: 3px;
  left: 3px;
  background: #00afef;
}
