.login__container {
  min-height: 100vh;
  background-color: #eeeeee;
  display: flex;
}

.login__content {
  width: 25%;
  max-width: 600px;
  min-width: 320px;
  margin: auto;
  background-color: #ffffff;
  padding: 5rem 30px;
}

.lc__header {
  text-align: center;
}

.lc__header h4 {
  text-transform: uppercase;
  color: #a4a6b3;
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 30px;
}

.lc__header h2 {
  margin: 20px 0;
  font-weight: 700;
  font-size: 1.6rem;
  text-transform: capitalize;
  color: #252733;
}

.login-btn {
  text-align: center;
  margin-bottom: 20px;
}

.login-btn button {
  width: 100%;
}

.login-signup {
  text-align: center;
  margin: 10px 0;
}

.login-signup p {
  font-size: 1rem;
  color: #818181;
}

.login-signup p a {
  color: #00afef;
}

.login-pwd {
  position: relative;
}

.login-pwd input {
  padding-right: 40px !important;
}

.login-pwd-icon {
  position: absolute;
  top: 50%;
  font-size: 1.4rem;
  right: 10px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #898383;
  cursor: pointer;
}

.forgot {
  color: #9fa2b4;
  font-size: 0.9rem;
}

.bg-btn-primary {
  background-color: #00afef !important;
  color:#fff !important;
}