.Lesson_detail {
  padding: 10px;
}
.lesson_details_container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 20px;
}
/* Lesson Details Banner */
.LD_banner_video {
  background-color: #222222;
  border-radius: 10px;
}
.LD_banner_video img,
.LD_banner_video video {
  width: 100%;
  height: 400px;
  border-radius: 10px;
  object-fit: contain;
}
.LD_banner--content {
  padding: 10px 0;
}
.LD_banner--header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.LD_banner--message {
  font-size: 13px;
  color: #6e6e6e;
  line-height: 1.5;
  margin-top: 5px;
}
/* Lesson Details Activity */
.lesson_details--activity {
  padding: 10px;
  border-radius: 10px;
  box-shadow: 2px 2px 10px rgba(233, 233, 233, 0.5);
  border: 1px solid #f0f0f0;
}
.LD_activity--header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.LD_activity-icon {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid #454545;
}
.LD_activity-icon svg {
  font-size: 20px;
}
.LD_activity--header h4 {
  padding: 5px 10px;
  font-size: 14px;
  border: 1px solid #454545;
  border-radius: 5px;
}
.LD_activity_content h2 {
  font-size: 20px;
  margin: 10px 0;
  font-weight: 800;
}
.LD_activity_content h5 {
  font-size: 14px;
}
.LD_activity_content p {
  font-size: 13px;
  line-height: 1.6;
  color: #808080;
  margin-top: 10px;
}
.LD_activity_footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}
.LD_activity_footer h5 {
  font-size: 15px;
  margin-bottom: 5px;
}
.LD_activity_resource button {
  font-size: 13px;
  border: 1px solid #00afef;
  padding: 5px 15px;
  border-radius: 5px;
  background: none;
  color: #00afef;
}
.LD_activity_form input {
  display: none;
}
.LD_activity_form label {
  padding: 5px 70px;
  border: 2px dotted #dedede;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
}
.LD_activity_form label svg {
  display: block;
  text-align: center;
  margin: auto;
}
/* Lesson Details Forum */
.LDF_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}
.LDF_form {
  position: relative;
}
.LDF_form textarea {
  width: 100%;
  padding: 5px;
  padding-right: 50px;
  border: 1px solid #dedede;
  border-radius: 5px;
  outline: none;
  height: 80px;
}
.LDF_form--btn {
  position: absolute;
  top: 5px;
  right: 10px;
  cursor: pointer;
  border: none;
  background: none;
  border-radius: 5px;
  transform: all 0.5s ease;
  padding: 5px;
}
.LDF_form--btn:hover {
  background-color: #eeeeee;
  transform: scale(1.2);
}
.LDF_form--btn svg {
  font-size: 22px;
}
/* Chat */
.LDF_header {
  margin: 10px 0;
  border-top: 1px solid #dedede;
  padding-top: 10px;
}
.LDF_header a {
  font-size: 13px;
}
.LDF_header a svg {
  font-size: 16px;
}
.LDF_chat_container {
  display: grid;
  gap: 10px;
}
.LDF_chat_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}
.LDF_chat_header svg {
  font-size: 18px;
}
.LDF_chat_header p {
  font-size: 12px;
  color: grey;
}
.LDF_chat--name {
  margin-bottom: 5px;
  font-size: 15px;
}
.LDF_chat--name span {
  color: grey;
  font-size: 13px;
}
.LDF_chat--content {
  box-shadow: 2px 2px 10px rgba(233, 233, 233, 0.5);
  border: 1px solid #f0f0f0;
  padding: 5px;
  border-radius: 5px;
}
.LDF_chat--message {
  font-size: 13px;
  color: #6e6e6e;
  line-height: 1.7;
}
/* Lesson Content */
.LD_content--grid {
  display: grid;
  gap: 10px;
  margin-top: 10px;
}
.LD_content--module {
  background-color: #f9f9f9;
  border-radius: 5px;
  padding: 10px;
}
.LD_content--module-header {
  position: relative;
  width: 100%;
  text-align: left;
  border: none;
  cursor: pointer;
  outline: none;
  background: none;
}
.LD_content--module-header h4 {
  font-size: 16px;
  margin-bottom: 5px;
}
.LD_content--module-header p {
  color: grey;
  font-size: 13px;
}
.LD_content--header-icon {
  position: absolute;
  top: 0;
  right: 5px;
}
.LD_content--header-icon svg {
  font-size: 16px;
  color: grey;
}
.LD_content--module-content {
  display: none;
}
.LD_content--module-content.active {
  display: block;
}
.LD_content--module-content ul {
  margin-top: 10px;
}
.LD_content--module-content ul li {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 5px;
  cursor: pointer;
}
.LD_content--module-content ul li span {
  font-size: 13px;
  text-transform: capitalize;
}
.LD_content--module-content ul li.active {
  color: #00afef;
}
.LD_content_icon_active {
  color: #00afef;
}
