/* Root CSS */
@media (max-width: 1024px) {
  .layout-width {
    width: 100%;
  }
}
/* Login */
@media (max-width: 768px) {
  .lc__header img {
    width: 60px;
  }
  .lc__header h4 {
    font-size: 0.9rem;
  }
  .lc__header h2 {
    font-size: 1.5rem;
  }
}
@media (max-width: 425px) {
  .login__content {
    width: 90%;
  }

  .lp__container,
  .login__container {
    background-color: #fff;
  }
  .project__img {
    display: none;
  }
}

/* Sidebar */
@media (max-width: 1024px) {
  .sidebar {
    display: none;
  }
  .sidebar-menuactive {
    position: fixed;
    left: 0;
    right: 0;
    top: 50px;
    bottom: 0;
    overflow-y: auto;
    display: block;
    z-index: 10;
  }
}

/* Navbar */
@media (max-width: 1024px) {
  .nav_bar {
    position: fixed;
    width: 100%;
    background: #ffffff;
    z-index: 10;
    left: 0;
  }
  .navbar__menu {
    display: block;
  }
  .space-top {
    margin-top: 70px;
  }
}
@media (max-width: 425px) {
  .nav_bar {
    min-width: 320px;
  }
}

/* Project */
@media (max-width: 800px) {
  .project__container {
    grid-template-columns: repeat(1, 1fr);
    padding: 10px;
  }
  .project__img {
    display: none;
  }
  .project__card1 {
    padding: 10px;
  }
}
@media (max-width: 425px) {
  .project__card-body {
    width: 95%;
  }
  .project__card2-title {
    width: 100%;
  }
  .project__timer h3 {
    font-size: 1.5rem;
  }
  .project__timer h3 span {
    font-size: 0.8rem;
  }
  .login__container .shadow {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
  }
}

/* Button */
@media (max-width: 768px) {
  .button {
    font-size: 0.8rem;
  }
}

/* Cards */
@media (max-width: 1200px) {
  .dashboard__cards {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 600px) {
  .dashboard__cards {
    grid-template-columns: repeat(1, 1fr);
  }
}
/* Modules */
@media (max-width: 1024px) {
  .courses_modules {
    margin: 0;
  }
}
@media (max-width: 768px) {
  .modul_divider::after,
  .modul_divider::before {
    width: 30%;
  }
  .mod_details {
    display: block;
  }
  .mod_details_left {
    width: 100%;
    position: static;
    min-width: 400px;
  }
  .module_outline {
    width: 100%;
    min-width: 400px;
  }
  .mod_details_sections {
    display: block;
  }
  .mod_details_sect {
    width: 100%;
    margin-bottom: 10px;
  }
  .mod_details_sect:not(:last-child) {
    border-right: none;
  }
}

@media (max-width: 450px) {
  .modul__compl_divider {
    min-width: 260px;
    overflow-x: auto;
  }
  .modul_divider::after,
  .modul_divider::before {
    width: 25%;
  }
}
/* Profile */
@media (max-width: 768px) {
  .profile--container {
    width: 98%;
    grid-template-columns: 1fr;
    grid-gap: 20px;
    gap: 20px;
    min-width: 320px;
  }
  .modal--container {
    top: 1px;
    height: 99vh;
    overflow-y: auto;
    border-radius: 5px;
    width: 98%;
    min-width: 320px;
  }
}
/* Dashboard */
@media (max-width: 768px) {
  .dashboard_body {
    grid-template-columns: repeat(1, 1fr);
  }
  .card2__icon img {
    width: 70px;
    height: 70px;
  }
  .db_course_card h2 {
    font-size: 20px;
  }
}
/* Payments */
@media (max-width: 1024px) {
  .payments_cards {
    margin-top: 70px;
  }
}
@media (max-width: 768px) {
  .payments_cards {
    grid-template-columns: repeat(2, 1fr);
  }
  .payments_table_container {
    min-width: 400px;
    overflow-x: auto;
  }
}
@media (max-width: 550px) {
  .payments_cards {
    grid-template-columns: repeat(1, 1fr);
  }
  .payments_summary_card {
    min-width: 400px;
  }
}
/* Attendance */
@media (max-width: 1500px) {
  .attendance_in_weeks_contents {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 1100px) {
  .attendance_summary_cards {
    grid-template-columns: repeat(1, 1fr);
  }
}
@media (max-width: 1024px) {
  .attendance_summary {
    width: 100%;
    margin-bottom: 20px;
  }
  .attendance_container {
    display: block;
    margin-top: 70px;
  }
  .attendance_in_weeks {
    width: 100%;
  }
}
@media (max-width: 550px) {
  .attendance_summary {
    min-width: 400px;
  }
  .attendance_in_weeks_contents {
    grid-template-columns: repeat(1, 1fr);
  }
  .attendance_container {
    min-width: 400px;
    background-color: #f9f9f9;
  }
}
/* Projects */
@media (max-width: 1024px) {
  .projects_container {
    margin-top: 70px;
  }
  .single_project_container {
    margin-top: 70px;
  }
}
@media (max-width: 768px) {
  .projects_container {
    grid-template-columns: repeat(1, 1fr);
  }
  .single_project_container {
    display: block;
  }
  .single_project,
  .project_submission {
    width: 100%;
    min-width: 400px;
  }
}
/* Profile */
@media (max-width: 550px) {
  .profile {
    min-width: 350px;
  }
}
/* Password */
@media (max-width: 1024px) {
  .password_content {
    margin-top: 70px;
  }
}
/* @media */
@media (max-height: 550px) {
  .modal--container {
    top: 1px;
    height: 99vh;
    overflow-y: auto;
    border-radius: 5px;
  }
  .profile--modal {
    position: relative;
    border-radius: 0 0 5px 5px;
  }
  .modal--body {
    max-height: none;
    height: auto;
    overflow-y: auto;
    margin: 0;
  }
  .modal--actions-btns {
    position: unset;
  }
  .modal--head {
    position: unset;
  }
  .modal--close-btn {
    margin: 5px 5px 0 0;
  }
}
/* Welcome Page */
@media (max-width: 1024px) {
  .welcome {
    margin-top: 60px;
  }
  .welcome_content_text {
    font-size: 45px;
    max-width: 90%;
  }
  .welcome_content_countdown {
    font-size: 40px;
  }
  .Welcome_bottom_link {
    width: 150px;
    padding: 10px;
    height: 40px;
    overflow: hidden;
  }
}
@media (max-width: 425px) {
  .welcome {
    min-width: 330px;
  }
  .welcome_content_text {
    font-size: 35px;
  }
  .welcome_content_countdown {
    font-size: 30px;
  }
  .welcome_content_bottom {
    gap: 10px;
  }
}
/* Live Classes */
@media (max-width: 1024px) {
  .live_classes_grid {
    margin-top: 65px;
  }
}
@media (max-width: 768px) {
  .live_classes_grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 500px) {
  .live_classes_grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
