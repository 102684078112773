.courses_modules {
  margin: 20px;
}
.module__grid {
  display: flex;
  gap: 20px;
  padding: 10px 0;
  overflow-x: auto;
}
.module__grid a {
  color: #000000;
}
.modul__compl_divider {
  margin: 40px 0;
  margin-top: 80px;
}
.modul_divider {
  text-align: center;
  font-weight: 600;
  color: #a1a1a1;
  position: relative;
  font-size: 13px;
}
.modul_divider::after,
.modul_divider::before {
  content: "";
  width: 40%;
  background-color: #a1a1a1;
  height: 2px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.modul_divider::after {
  left: 0;
}
.modul_divider::before {
  right: 0;
}
/* Module Details */
.mod_details {
  display: flex;
  gap: 30px;
  padding: 10px;
  align-items: flex-start;
  position: relative;
}
.mod_details_left {
  background-color: #ffffff;
  padding: 20px;
  width: 70%;
  position: sticky;
  top: 10px;
}
.mod_details_header {
  width: 100%;
  position: relative;
}
.mod_details_header img {
  width: 100%;
  height: 230px;
  object-fit: cover;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}
.mod_details_status {
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 5px 20px;
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
  border-radius: 5px;
  font-size: 14px;
}
.mod_details_content {
  padding: 20px 0;
}
.mod_details_title {
  margin-bottom: 20px;
}
.mod_details_title h2 {
  font-size: 22px;
  font-weight: 900;
}
.mod_details_duration {
  display: flex;
  gap: 20px;
}
.mod_details_duration p {
  font-size: 13px;
}
.mod_details_duration p span {
  color: #00afef;
}
.mod_details h4 {
  font-size: 17px;
  margin-bottom: 5px;
}
.mod_details_descrp p {
  line-height: 1.7;
  font-size: 14px;
  color: #5c5c5c;
}
.mod_details_sections {
  margin: 30px 0;
  display: flex;
  gap: 20px;
}
.mod_details_sect {
  width: 20%;
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 14px;
}
.mod_details_sect span {
  font-weight: 600;
}
.mod_details_sect:not(:last-child) {
  border-right: 1px solid #c7c7c7;
}
/* Modules Details */
.mod_details_tab_header {
  display: flex;
  justify-content: center;
  gap: 50px;
  border-bottom: 1px solid #dedede;
}
.mod_tab_btns {
  background: none;
  color: #000000;
  border: none;
  cursor: pointer;
  position: relative;
  padding: 7px 20px;
  border-bottom: 2px solid #000000;
  font-weight: 600;
}
.mod_tab_btns::after {
  position: absolute;
  content: "0";
  background: #000000;
  padding: 5px;
  border-radius: 50%;
  font-size: 13px;
  color: white;
  top: 50%;
  transform: translateY(-50%);
  height: 17px;
  width: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
}
.mod_tab_btns.active {
  color: #00afef;
  border-bottom: 2px solid #00afef;
}
.mod_tab_btns.active::after {
  background: #00afef;
}
/* Module Outline */
.module_outline {
  width: 30%;
  padding: 20px 0;
  border-radius: 5px;
  background-color: white;
}
.module_outline_header {
  border-bottom: 1px solid #dedede;
  padding: 0 10px;
}
.module_outline_header h4 {
  font-size: 25px;
  margin-bottom: 10px;
  color: #3f3f3f;
}
.module_outline_header p {
  margin-bottom: 5px;
}
.module_breakdown {
  display: grid;
  gap: 10px;
}
.module_outline_content {
  padding: 10px;
}
.module_outline_content:not(:last-child) {
  border-bottom: 1px solid #dedede;
}
.module_outline_content h4 {
  position: relative;
}
.module_outline_content h4::after {
  content: "";
  width: 78%;
  position: absolute;
  height: 1px;
  background-color: #5c5c5c;
  border-radius: 5px;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.module_outline_content p {
  /* font-weight: 500; */
  font-size: 13px;
}
.module_oultine_items {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 10px;
}
.module_oultine_items svg,
.module_outline_assignment svg,
.module_outline_classwork svg {
  font-size: 18px;
}
.module_outline_assignment,
.module_outline_classwork {
  margin-bottom: 10px;
  display: flex;
  gap: 5px;
}
.module_outline_classwork strong,
.module_outline_assignment strong {
  color: #00afef;
  margin-right: 5px;
}
.mod_assignments_header {
  padding: 0 10px;
  background-color: #ececec;
  margin: 10px 0;
  font-size: 22px;
  cursor: pointer;
  width: 100%;
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mod_assignments_header svg {
  font-size: 18px;
}
.mod_assignment_descrp {
  font-size: 13px;
  line-height: 1.7;
  margin-bottom: 10px;
  padding: 0 10px;
}
.mod_assignment_content {
  box-shadow: 2px 2px 10px rgba(208, 208, 208, 0.4);
  margin-bottom: 10px;
}
.mod_assignment_footer {
  padding: 0 10px 10px 10px;
}
.mod_ass__content {
  display: none;
}
.mod_ass__content.active {
  display: block;
}
/* Module Forum */
.module_forum {
  margin-top: 10px;
}
.module_forum_header {
  position: relative;
  box-shadow: 2px 2px 10px rgba(208, 208, 208, 0.4);
}
.module_forum_header input {
  width: 100%;
  padding: 14px 10px;
  padding-right: 80px;
  border: 1px solid #dedede;
  border-radius: 5px;
}
.module_forum_header button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 7px 10px;
  background-color: #00afef;
  color: white;
  border: none;
  right: 10px;
  border-radius: 5px;
}
.module_forum_header button svg {
  font-size: 28px;
}
.module_chat_header {
  display: flex;
  position: relative;
  align-items: center;
  margin-bottom: 10px;
}
.module_chat_header p {
  position: absolute;
  right: 0;
}
.module_chat_header h5 {
  margin: 0 5px;
  font-size: 14px;
}
.module_forum_contents {
  margin-top: 10px;
  display: grid;
  gap: 10px;
}
.module_forum_content {
  box-shadow: 2px 2px 10px rgba(208, 208, 208, 0.4);
  padding: 10px;
  border-radius: 5px;
}
.module_forum_chat {
  line-height: 1.7;
  font-size: 13px;
  color: #3f3f3f;
  margin-top: 10px;
}
