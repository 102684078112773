.attendance_container {
  display: flex;
  gap: 30px;
  padding: 10px;
  align-items: flex-start;
}
.attendance_summary {
  width: 35%;
}
.attendance_summary_cards {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 20px;
}
.attendance_current {
  margin-top: 20px;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 20px;
  text-align: center;
  display: grid;
  gap: 10px;
  justify-content: center;
}
.attendance_current svg {
  font-size: 17px;
}
/* Attendance in Weeks */
.attendance_in_weeks {
  width: 65%;
  padding: 10px;
  border-radius: 10px;
  min-height: 100vh;
  background-color: white;
}
.attendance_in_weeks_header {
  padding: 10px;
}
.attendance_in_weeks_contents {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-top: 20px;
}
.attendance_in_weeks_content {
  box-shadow: 2px 2px 10px rgba(211, 211, 211, 0.5);
  padding: 10px;
  border-radius: 5px;
  text-align: center;
}
.attendance_in_weeks_content h5 {
  font-size: 14px;
}
.attendance_days {
  display: flex;
  justify-content: space-between;
}
.attendance_day {
  padding: 10px;
}
.attendance_day button {
  padding: 8px;
  border: 1px solid #dedede;
  background: none;
  border-radius: 5px;
  margin: 5px 0;
}
.attendance_day_present {
  color: green;
}
.attendance_day_absent {
  color: red;
}
.attendance_day button svg {
  font-size: 18px;
}
