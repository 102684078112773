.live_classes_grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  padding: 10px;
}
.live_classes {
  box-shadow: 2px 2px 10px rgba(226, 226, 226, 0.5);
  border-radius: 5px;
  position: relative;
  text-decoration: none;
  color: #000000;
  background: url(../../assets/img/live_class_bg.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.live_classes_bg {
  height: 160px;
  text-align: center;

  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  padding: 20px;
}
.live_classes_status {
  position: absolute;
  top: 0;
  left: 0;
  padding: 5px 30px;
  border-top-left-radius: 5px;
  font-size: 13px;
  text-transform: capitalize;
}
.live_classes_status.completed {
  background-color: green;
  color: white;
}
.live_classes_status.upcoming {
  background-color: orange;
  color: white;
}
.live_classes_status.live {
  background-color: red;
  color: white;
}
.live_classes_status.missed {
  background-color: #b80000;
  color: white;
}
.live_classes_content {
  padding: 10px;
  box-shadow: 2px 2px 10px rgba(226, 226, 226, 0.5);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: #ffffff;
}
.live_classes_content h4 {
  font-size: 14px;
  padding-bottom: 5px;
  font-weight: 600;
  letter-spacing: 1px;
}
.live_classes_content p {
  font-size: 13px;
}
/* Live Class */

.live_classbg {
  height: 300px;
  background-image: url(../../assets/img/live_class_bg.png);
  background-size: cover;
  background-size: contain;
  background-repeat: no-repeat;
  box-shadow: 2px 2px 10px rgba(226, 226, 226, 0.5);
  background-position: 34% 100%;
  background-position: center;
  background-color: #ffffff;
  margin-top: 3px;
}
.live_class_banner-descr_top {
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
  background-color: #00afef;
  align-items: center;
  border-top: 1px solid #7c7c7c;
  color: #ffffffff;
}
.live_class_banner-descr_bottom {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 15px 10px;
  box-shadow: 2px 2px 10px rgba(226, 226, 226, 0.5);
}
.live_class_banner-descr_bottom p a {
  font-size: 13px;
  color: #ff4d4d;
}
/* Live Class Tab */
.live_class_tab_links {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  border: 1px solid #dedede;
}
.live_class_tab_links button {
  border: none;
  cursor: pointer;
  padding: 10px 0;
  display: block;
  position: relative;
}
.live_class_tab_links button.active {
  background: #ffffff;
  box-shadow: 2px 2px 10px rgba(226, 226, 226, 0.5);
}
.live_class_tab_links button:not(:last-child) {
  border-right: 1px solid #dedede;
}
.LiveClassTabDescription {
  padding: 10px 10px 10px 10px;
}
.LiveClassTabDescription h2,
.LiveClassTabObjective h2 {
  font-size: 22px;
  margin-bottom: 10px;
}
.LiveClassTabDescription p {
  line-height: 1.9;
  font-size: 14px;
  color: #787878;
  font-weight: 300;
}
/* Live Class Objective */
.LiveClassTabObjective {
  padding: 10px;
}
.LiveClassTabObjective ul {
  margin: 10px;
  display: grid;
  gap: 10px;
}
.LiveClassTabObjective ul li {
  list-style-type: disc;
  font-weight: 300;
}
